import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-5.png');

class Specialization extends React.Component {
    render() {
        return (
          <>
            <div
              className='section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1'
              style={{ backgroundImage: 'url(' + bnr1 + ')' }}
            >
              <div className='container'>
                {/* IMAGE CAROUSEL START */}
                <div className='section-content'>
                  <div
                    className='row'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='col-lg-3 col-md-4 col-sm-6 m-b30'>
                      <div className='image-effect'>
                        <img
                          src={require('../../images/Welcome-1.png')}
                          alt=''
                        />
                        {/* <div className='figcaption'>
                          <h4>Engineering</h4>
                          <p>Excellence in Motion!</p>
                          <NavLink to='/project-detail'>
                            <i className='link-plus bg-primary' />
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4  col-sm-6 m-b30'>
                      <div className='image-effect'>
                        <img
                          src={require('../../images/Welcome-2.png')}
                          alt=''
                        />
                        {/* <div className='figcaption'>
                          <h4>Architecture</h4>
                          <p>Life is Architecture.</p>
                          <NavLink to='/project-detail'>
                            <i className='link-plus bg-primary' />
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-4  col-sm-6 m-b30'>
                      <div className='image-effect'>
                        <img
                          src={require('../../images/Welcome-3.png')}
                          alt=''
                        />
                        {/* <div className='figcaption bg-dark'>
                          <h4>Renovation</h4>
                          <p>Dazzling Design</p>
                          <NavLink to='/project-detail'>
                            <i className='link-plus bg-primary' />
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-12 col-sm-6 m-b30'>
                      <div className='mt-box our-speciallization-content'>
                        <h3 className='m-t0'>
                          <span className='font-weight-100'>Engineering</span>{' '}
                          <br />
                          Excellence in Motion!
                        </h3>
                        <p>
                          We will ensure to providing quality resources &
                          applying continual improvement techniques.
                        </p>
                        <NavLink
                          to='/#'
                          className='site-button btn-effect'
                        >
                          View All
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hilite-title text-right p-r50 text-uppercase text-pop-up-top'>
                <strong>Welcome</strong>
              </div>
            </div>
          </>
        );
    }
};

export default Specialization;