import React from 'react';
import { NavLink } from 'react-router-dom';

const machines = [
  {
    image: require('./../../images/machines/Machine-1.png'),
    title: 'ECOCA MT415',
    description: 'Capacity : Dia. 400mm x 2000mm',
  },
  {
    image: require('./../../images/machines/Machine-2.png'),
    title: 'ACE DESIGNERS Mega Jobber',
    description: 'Capacity : Dia. 300mm x 600 Long',
  },
  {
    image: require('./../../images/machines/Machine-3.png'),
    title: 'PMT Supercut 6N',
    description: 'Capacity : Dia. 600mm x 6 Module',
  },
  {
    image: require('./../../images/machines/Machine-4.png'),
    title: 'LEADWELL MCV1000',
    description: 'Capacity : 1000mm x 500mm x 500mm',
  },
  {
    image: require('./../../images/machines/Machine-5.png'),
    title: 'AMS MCV350',
    description: 'Capacity : 400mmx350mmx400mm',
  },
  {
    image: require('./../../images/machines/Machine-6.png'),
    title: 'Challenger ',
    description: 'Capacity : 600mm x 400mm x 400mm',
  },
];

var bnr1 = require('./../../images/background/bg-machine.jpg');

class Blogs extends React.Component {
  render() {
    return (
      <>
        <div
          className='section-full mobile-page-padding p-t80 p-b30 square_shape1 bg-parallax bg-cover'
          data-stellar-background-ratio='0.5'
          style={{ backgroundImage: 'url(' + bnr1 + ')' }}
        >
          <div className='container'>
            {/* TITLE START */}
            <div className='section-head '>
              <div className='mt-separator-outer separator-center'>
                <div className='mt-separator'>
                  <h2 className='text-white text-uppercase sep-line-one '>
                    <span className='font-weight-300 text-primary'>
                      Machine
                    </span>{' '}
                    Tools
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className='section-content'>
              <div className='row'>
                {machines.map((item, index) => (
                  <div className='col-md-4 col-sm-6' key={index}>
                    <div className='mt-box blog-post latest-blog-3 date-style-1 bg-white m-b30'>
                      <div className='mt-post-media mt-img-overlay7'>
                        <NavLink to='/#'>
                          <img src={item.image} alt='' />
                        </NavLink>
                      </div>
                      <div className='mt-post-info p-a30'>
                        <div className='post-overlay-position'>
                          <div className='mt-post-title '>
                            <h4 className='post-title m-b0'>{item.title}</h4>
                          </div>
                          <div className='mt-post-text'>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='hilite-title text-right p-r50 text-uppercase hilite-dark'>
            <strong>Blog</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Blogs;
