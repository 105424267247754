import React from 'react';
// import { NavLink } from 'react-router-dom';

const teamMembers = [
  {
    image: require('./../../images/our-team4/pic1.png'),
    membername: 'Mangu Prajapati',
    position: 'Director',
    email: 'dkenggvapi@rediffmail.com',
    mobile: '+91-9824062206',
    description:
      'Oversees company strategy, operations, and growth initiatives.',
  },
  {
    image: require('./../../images/our-team4/pic2.png'),
    membername: 'Chetan Lad',
    position: 'Head Engineer',
    email: 'chetan.lad83@gmail.com',
    mobile: '+91-8141562535',
    description:
      'Leads technical projects, designs, and innovation in mechanical engineering.',
  },
  {
    image: require('./../../images/our-team4/pic3.png'),
    membername: 'Ilesh Prajapati',
    position: 'Commercial',
    email: 'dkenggvapi@gmail.com',
    mobile: '+91-9924116602',
    description:
      'Manages sales, marketing, and client relations to drive business growth.',
  },
];

class Team3 extends React.Component {
  render() {
    return (
      <>
        <div className='section-full p-t80 p-b50 bg-white inner-page-padding'>
          <div className='container'>
            {/* TITLE START */}
            <div className='section-head'>
              <div className='mt-separator-outer separator-left'>
                <div className='mt-separator'>
                  <h2 className='text-uppercase sep-line-one '>
                    <span className='font-weight-300 text-primary'>Our</span>{' '}
                    Team
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE Team START */}
            <div className='section-content'>
              <div className='row'>
                {teamMembers.map((item, index) => (
                  <div
                    key={index}
                    className='col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30   '
                  >
                    <div className='our-team-1 hover-animation-1'>
                      <div className='profile-image scale-in-center'>
                        <img src={item.image} alt='' />
                      </div>
                      <div className='figcaption'>
                        <h4>{item.membername}</h4>
                        <h5>{item.position}</h5>
                        <p>{item.description}</p>
                        <span>{item.email}</span> <br />
                        <span>{item.mobile}</span>
                        {/* <div className='icons'>
                          <NavLink to={'#'}>
                            <i className='fa fa-facebook' />
                          </NavLink>
                          <NavLink to={'#'}>
                            {' '}
                            <i className='fa fa-twitter' />
                          </NavLink>
                          <NavLink to={'#'}>
                            {' '}
                            <i className='fa fa-instagram' />
                          </NavLink>
                          <NavLink to={'#'}>
                            <i className='fa fa-rss' />
                          </NavLink>
                          <NavLink to={'#'}>
                            {' '}
                            <i className='fa fa-youtube' />
                          </NavLink>
                          <NavLink to={'#'}>
                            {' '}
                            <i className='fa fa-linkedin' />
                          </NavLink>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Team3;
